import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import '../App.css'

export const DetialNews = () => {
  const {id} = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`https://ogtorgui.zyberapi.site/post/${id}`)
    .then((response) => setData(response.data))
    .catch((err) => console.log(err));
  });

  return (
    <>
    <div className="contain">
        {/* <img src={data.photo} alt="" /> */}
        <div>
            <h2>{data.title}</h2>
            <div className="content" dangerouslySetInnerHTML={{__html:data.content}}/>
        </div>
    </div>
    </>
  )
}
