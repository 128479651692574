import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useEffect, useState } from "react";
import axios from "axios";
import { DataCard } from "./dataCard";

export const Projects = () => {

  const [data, setData] = useState([]);
  const [techdata, setTechData] = useState([]);

  useEffect(() => {
    axios.get(`https://ogtorgui.zyberapi.site/categories/648de7b7cb35fabf7bb3f1b6/post?sort=-createdAt`)
    .then((response) => setData(response.data.data))
    .catch((err) => console.log(err));
    axios.get(`https://ogtorgui.zyberapi.site/categories/648de7c2cb35fabf7bb3f1b8/post?sort=-createdAt`)
    .then((response) => setTechData(response.data.data))
    .catch((err) => console.log(err));
  });
  console.log(data);

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Мэдээлэл</h2>
                <p>Дээр нь дарж тухайн мэдээллийн дэлгэрэнгүйг үзнэ үү.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Шинжлэх ухаан</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Технологи</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          data.map((project, index) => {
                            return (
                              <DataCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          techdata.map((data, index) => {
                            return <DataCard key={index} {...data}/>
                        })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
