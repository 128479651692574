import { Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

export const DataCard = ({ title, summary, cover, _id }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={'https://ogtorgui.zyberapi.site/'+cover} />
        <p>{title}</p>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <h5>{summary}</h5>
          <Link to={`/detialnews/${_id}`} className='link-button'>Дэлгэрэнгүй</Link>
        </div>
      </div>
    </Col>
  )
}
