import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useEffect, useState } from "react";
import axios from "axios";
import { DataCard } from "./dataCard";
import { useParams } from "react-router-dom";

export const CategoryDetialPage = ({cat}) => {
  const {id} = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`https://ogtorgui.zyberapi.site/categories/${id}/post`)
    .then((response) => setData(response.data.data))
    .catch((err) => console.log(err));
  });
  

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Мэдээлэл</h2>
                <p>Та доошоо явуулж мэдээгээ үзнэ үү. Хамгийн сүүлд нэмэгдсэн мэдээ хамгийн эхэнд байгаа.</p>
                <Tab.Container className="cat-detial-container" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          data.map((project, index) => {
                            return (
                                    <DataCard
                                    key={index}
                                    {...project}
                                    />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
